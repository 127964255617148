import React from "react";
import CustomPopup from "../../custom-popup/CustomPopup";
import AppointmentPopupHeader from "../../components/appointment-popup/AppointmentPopupHeader";
import AppointmentTextField from "../../components/appointment-popup/AppointmentTextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  RequestChangePassword,
} from "../../utils/types/requestType";
import {
  confirmPasswordSchema,
  passwordSchema,
} from "../../utils/validation";
import { useTranslation } from "react-i18next";
import { constantString } from "../../utils/constantString";

import CircularLoader from "../loading/CircularLoader";
import "./changePasswordStyle.css";
import { useAppDispatch, useAppSelector } from "../../redux";
import { selectServiceLoading } from "../../redux/service/selector";
import { selectOwnerData, selectOwnerLoading } from "../../redux/auth/selector";
import { updatePasswordAPI } from "../../redux/auth/action";
import { setUpdatePassword } from "../../redux/meta/slice";

const Changepassword = ({ open }: { open: boolean }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectOwnerLoading);
  const ownerData = useAppSelector(selectOwnerData);

  const { t } = useTranslation();
  const customStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "0",
    height: "100vh",
    boxShadow: 24,
    // p: 4,
  };

  const validationSchema = Yup.object({
    oldPassword: passwordSchema(),
    newPassword: passwordSchema(),
    confirmPassword: confirmPasswordSchema("newPassword"),
  });

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const body: RequestChangePassword = {
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword,
        confirmPassword: values?.confirmPassword,
      };
      dispatch(
        updatePasswordAPI({
          values: body,
          id: ownerData?.id,
        })
      );
    },
  });

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    formik;

  return (
    <>
      {isLoading && <CircularLoader />}
      <CustomPopup open={open} style={customStyle}>
        <AppointmentPopupHeader
          onClose={() => {
            dispatch(setUpdatePassword(false));
          }}
        >
          <b>Update password</b>
        </AppointmentPopupHeader>
        <form onSubmit={handleSubmit}>
          <div className="password-container">
            <AppointmentTextField
              name="oldPassword"
              type="password"
              label={t(constantString.OLD_PASSWORD)}
              placeholder="*******"
              onChange={handleChange}
              value={values.oldPassword}
              maxLength={50}
              onBlur={handleBlur}
              error={touched.oldPassword && errors.oldPassword}
              mandatory={true}
            />

            <AppointmentTextField
              name="newPassword"
              type="password"
              label={t(constantString.NEW_PASSWORD)}
              placeholder="*******"
              onChange={handleChange}
              value={values.newPassword}
              maxLength={50}
              onBlur={handleBlur}
              error={touched.newPassword && errors.newPassword}
              passwordValidation={true}
              mandatory={true}
            />

            <AppointmentTextField
              name="confirmPassword"
              type="password"
              label={t(constantString.CONFIRM_PASSWORD)}
              placeholder="*******"
              onChange={handleChange}
              value={values.confirmPassword}
              maxLength={50}
              onBlur={handleBlur}
              error={touched.confirmPassword && errors.confirmPassword}
              mandatory={true}
            />

            <div className="btn-div">
              <Button
                variant="contained"
                className="contained-btn"
                type="submit"
              >
                {t(constantString.SAVE)}
              </Button>
            </div>
          </div>
        </form>
      </CustomPopup>
    </>
  );
};

export default Changepassword;
